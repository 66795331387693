<template>
  <div class="page_bg">
    <!-- 顶部返回 -->
    <div class="back_top_name flex-r-sb-c" style=" padding: 0.32rem;background: #ffffff;font-size: 0.48rem;">
      <van-icon name="arrow-left" @click="backPath" />
      <div class="top_name">我的地址</div>
      <div class="top_icon" />
    </div>
    <div class="address_box" style="margin-bottom:2rem">
      <van-address-list v-model="chosenAddressId" :list="list" default-tag-text="默认" :switchable="isChoose"
                        add-button-text="+ 新增地址" @add="onAdd" @edit="onEdit" @select="selectAddress"
      />
    </div>
    <van-empty v-if="list.length === 0" class="custom-image"
               image="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/my/address_empty.png"
               description="您还没有可用的地址..."
    />
  </div>
</template>

<script>
import './index.scss'
import { userReceiptAddress_list, userReceiptAddressList } from '@/api/user'
export default {
  data() {
    return {
      type: null,
      chosenAddressId: '',
      isChoose: false,
      list: []
    }
  },
  created() {
    this.type = this.$route.query.type || null
    this.chosenAddressId = this.$route.query.aId || ''
    this.productModuleId = this.$route.query.pmid || ''
    this.isChoose = this.$route.query.aId !== undefined
    if (this.type) {
      this.getAddressList2()
    } else {
      this.getAddressList()
    }
  },
  methods: {
    backPath() { window.history.back() },
    getAddressList() {
      userReceiptAddress_list().then(res => {
        for (const i in res.data) {
          const parms = {
            id: res.data[i].userReceiptAddressId,
            name: res.data[i].receiptPersonName,
            tel: res.data[i].receiptMobile,
            address: res.data[i].province.provinceName + res.data[i].city.cityName + res.data[i].area.areaName + res.data[i].receiptAddress,
            isDefault: res.data[i].isDefault
          }
          this.list.push(parms)
        }
      })
    },
    getAddressList2() {
      userReceiptAddressList({productModuleId: this.productModuleId}).then(res => {
        for (const i in res.data) {
          const parms = {
            id: res.data[i].userReceiptAddressId,
            name: res.data[i].receiptPersonName,
            tel: res.data[i].receiptMobile,
            address: res.data[i].province.provinceName + res.data[i].city.cityName + res.data[i].area.areaName + res.data[i].receiptAddress,
            isDefault: res.data[i].isDefault
          }
          this.list.push(parms)
        }
      })
    },
    selectAddress(item, index) {
      // var info = JSON.parse(sessionStorage.getItem('orderInfoUrl'))
      // this.$router.replace(`/order/subOrder?item=${info.item}&type=${info.type}`)
      this.backPath()
      sessionStorage.setItem('address', JSON.stringify(item))
    },
    onAdd() {
      if (this.list.length === 15) {
        this.$toast({ message: '最多可保存15个地址', duration: 1 * 1000 })
      } else {
        this.$router.push('/user/addressDetail?id=0')
      }
    },
    onEdit(item, index) {
      this.$router.push('/user/addressDetail?id=' + item.id)
    }
  }
}
</script>

<style>
</style>
